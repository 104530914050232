/* 
@font-face {
    font-family: 'OTF-Pixer-Regular-otf';
    src: url('./front/OTF/Pixer-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'TTF-Pixer-Regular-ttf';
    src: url('./front/TTF/Pixer-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'Pixer-Regular-eot';
    src: url('./front/WEB/Pixer-Regular.eot') format('opentype');
}
@font-face {
    font-family: 'Pixer-Regular-woff';
    src: url('./front/WEB/Pixer-Regular.woff') format('opentype');
}
@font-face {
    font-family: 'Pixer-Regular-woff2';
    src: url('./front/WEB/Pixer-Regular.woff2') format('opentype');
}
@font-face {
    font-family: 'IBMPlexMono-Medium';
    src: url('./front/IBM-Plex-Mono/IBMPlexMono-Medium.otf') format('opentype');
}

@font-face {
   font-family: 'IBMPlexMono-Medium';
   src: url('./front/IBM-Plex-Mono/IBMPlexMono-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'IBMPlexMono-Bold';
    src: url('./front/IBM-Plex-Mono/IBMPlexMono-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'IBMPlexMono-Italic';
    src: url('./front/IBM-Plex-Mono/IBMPlexMono-Italic.otf') format('opentype');
}

@font-face {
    font-family: 'DarkStar-Bold';
    src: url('./front/DarkStar-Bold.ttf') format('opentype');
}

 */


@font-face {
    font-family: 'Poppins';
    src: url('./front/Poppins/Poppins-Bold.ttf') format('opentype');
}

@font-face {
    font-family: 'VT323';
    src: url('./front//VT323-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'TT Interphases Pro Mono Trl';
    src: url('./front/TT-Interphases-Pro-Mono-Trl-Regular/TT\ Interphases\ Pro\ Mono\ Trial\ Bold.ttf') format('opentype');
}


/* @media only screen and (max-width: 768px) { */
@font-face {
    font-family: 'PARADOS';
    src: url('./front/PARaDOS.ttf') format('opentype');
}

/* } */