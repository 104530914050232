/* flex布局 */

.flex-out {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}

.flex-in {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-start {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.flex-end {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.row-start {
	display: flex;
	justify-content: flex-start;
}

.row-end {
	display: flex;
	justify-content: flex-end;
}

.row-in {
	display: flex;
	justify-content: center;
}

.row-out {
	display: flex;
	justify-content: space-between;
}

.col-start {
	display: flex;
	align-items: flex-start;
}

.col-end {
	display: flex;
	align-items: flex-end;
}

.col-in {
	display: flex;
	align-items: center;
}

.col-out {
	display: flex;
	align-items: stretch;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

/* 竖 */
.flex-col1 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.flex-col2 {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.flex-col3 {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.flex-col4 {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.flex-col5 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.flex-col6 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.flex-col7 {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.flex-col8 {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
}

.flex-col9 {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
}

/* 横 */
.flex-row1 {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.flex-row2 {
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.flex-row3 {
	display: flex;
	align-items: flex-start;
	justify-content: flex-end;
}

.flex-row4 {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.flex-row5 {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-row6 {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.flex-row7 {
	display: flex;
	align-items: flex-end;
}

.flex-row8 {
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.flex-row9 {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

/* 换行 */
.flex-wrap {
	flex-wrap: wrap;
}


/* box布局 */
.fit-rel {
	position: relative;
}

.fit-abs1 {
	position: absolute;
	top: 0;
	left: 0;
}

.fit-abs2 {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

.fit-abs3 {
	position: absolute;
	top: 0;
	right: 0;
}

.fit-abs4 {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.fit-abs5 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.fit-abs6 {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.fit-abs7 {
	position: absolute;
	left: 0;
	bottom: 0;
}

.fit-abs8 {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

.fit-abs9 {
	position: absolute;
	right: 0;
	bottom: 0;
}

.fit-out {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.fit-row {
	position: absolute;
	left: 0;
	right: 0;
}

.fit-col {
	position: absolute;
	top: 0;
	bottom: 0;
}

.fit-fixed {
	position: fixed;
	z-index: 9999;
}

.wh-all {
	width: 100%;
	height: 100%;
}

.w-all {
	width: 100%;
}

.h-all {
	height: 100%;
}

.h-auto {
	height: auto;
}

.wh-v {
	width: 100vw;
	height: 100vh;
}

.h-vh {
	height: 100vh;
}

.w-vw {
	width: 100vw;
}

.h-vh50 {
	height: 50vh;
}

.wh-max {
	width: max-content;
	height: max-content;
}

.w-max {
	width: max-content;
}

.h-max {
	height: max-content;
}

.scroll-y {
	overflow-y: scroll;
	overflow-x: hidden;
}

.scroll-x {
	overflow-y: hidden;
	overflow-x: scroll;
}

.scroll-h {
	overflow: hidden;
}

.hide {
	display: none !important;
}

/* .boxL {
	width: calc(100% - 10rem);
	margin-left: 10rem;
} */

.mask {
	position: relative;
	height: max-content;
	min-height: 100%;
}

.events-none {
	pointer-events: none;
}

.bgWrite {
	position: relative;
	width: 100%;
	height: 100%;
	background: url('../img/bg/write.jpg') no-repeat;
	background-size: 100% 100%;
}

.bgBlack {
	position: relative;
	width: 100%;
	height: 100%;
	background: url('../img/bg/black.jpg') no-repeat;
	background-size: 100% 100%;
}

.appBtn {
	cursor: pointer;
	z-index: 9;
	position: absolute;
	top: 6rem;
	right: 9.2rem;
	width: 13rem;
	height: 13rem;
	flex-shrink: 0;

	color: #EFD07E;
	text-align: center;
	text-shadow: 1.763px 0px 1.763px #D63CDE, 0px 1.763px 0px rgba(0, 0, 0, 0.25);
	font-family: VT323;
	font-size: 3.8789rem;
	font-style: normal;
	font-weight: 400;
	line-height: 3.4rem;
	/* 87.653% */
	letter-spacing: -0.0388rem;
	text-transform: uppercase;
	z-index: 9;
}

.appBtn::before {
	pointer-events: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	content: '';
	display: block;
	width: 262.5%;
	height: 262.5%;
	background: url('../img/bg/appBtn.png') no-repeat center;
	background-size: 100% 100%;
}

.appBtn:hover::before {
	background: url('../img/bg/appBtnHvr.png') no-repeat center;
	background-size: 13rem;
}

.appBtn:hover {
	color: #353535;
	text-align: center;
	text-shadow: 1.117px 0px 1.117px #D63CDE, 0px 1.117px 0px rgba(0, 0, 0, 0.25);
	font-family: VT323;
	font-size: 2.1533rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.9633rem;
	letter-spacing: -0.0215rem;
	text-transform: uppercase;
}

.page {
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.page.show {
	opacity: 1;
	pointer-events: auto;
}

.titleIdx {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5.1rem;
	height: 5.5rem;
	flex-shrink: 0;
	background: url('../img/bg/idxBg.png') no-repeat center;
	background-size: 4.9rem 4.9rem;
	margin-right: 1rem;
	color: #56CFE5;
	text-shadow: -3px 0px 1px rgba(222, 60, 60, 0.32), 6px -3px 0px rgba(17, 26, 255, 0.66);
	font-family: VT323;
	font-size: 3rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.03rem;
}

.bgBlack .titleIdx {
	color: #0B1338;
	text-align: center;
	text-shadow: 1.304px 0px 0px #B4FB56, 0px 1.304px 0px #51A7CF, 0px -1.304px 0px #AEC541, 2.609px 0px 0px rgba(73, 247, 49, 0.48);
	font-family: VT323;
	font-size: 3rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.03rem;

	background: url('../img/bg/idxBgWrite.png') no-repeat center;
	background-size: 100% 100%;
}

/* .titleGasp .animate__animated {
	transform: translate3d(0, 100%, 0);
} */

[data-animate='animate__fadeIn'] {
	opacity: 0;
}

.flex-h5,
.fit-h5 {
	display: none !important;
}

.animate__fade {
	opacity: 0;
}

@media only screen and (min-width: 768px) {
	.navBox>div:hover .nav {
		border: none;
		padding: 0;
		color: #8DD2DE;
		text-shadow: 1px 0px 1px #D63CDE, 0px 1px 0px rgba(0, 0, 0, 0.25);
		background: url(assets/img/footer/btnBg.png) no-repeat;
		background-size: 100% 100%;
	}

	.navBox .dotBox {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
	}

	.navBox .dot {
		display: block;
		width: 1rem;
		height: 1rem;
		flex-shrink: 0;
		border: .2rem solid #000;
		margin: .7rem .6rem;
		border-radius: 50%;
	}

	.navBox .dot:hover,
	.navBox .dot.active {
		background-color: #000;
	}
}

@media only screen and (max-width: 767px) {

	.animate__fade {
		opacity: 1;
	}

	.flex-pc,
	.fit-pc {
		display: none !important;
	}

	.fit-h5 {
		display: block !important;
	}

	.flex-h5 {
		display: flex !important;
	}


	.bgWrite {
		background: url('../img/bg/h5-write.jpg') no-repeat;
		background-size: 100% 100%;
	}

	.bgBlack {
		background: url('../img/bg/h5-black.jpg') no-repeat;
		background-size: 100% 100%;
	}

	.bgBlack .navBox .nav {
		color: #87D1DB;
		border: 1px solid #60CAD4;
		background: #091439;
		box-shadow: 0px 0px 1.12px 0px rgba(0, 0, 0, 0.25);
	}

	.bgBlack .navBox .nav.active {
		border-radius: 0.5rem;
		color: #0C1C68;
		background: #8BCFD8;
		mix-blend-mode: hard-light;
		border: 1px solid transparent;
		box-shadow: none;
		text-shadow: 1.4px 0px 1.4px #A4EFFF, 1.4px 1.4px 2.38px rgba(109, 161, 40, 0.48);
	}

	.bgBlack .navBox .nav.active span {
		border: 1px solid transparent;
	}

	.appBtn {
		z-index: 9;
		position: absolute;
		top: 3rem;
		right: 4.6rem;
		width: 9rem;
		height: 9rem;
		flex-shrink: 0;
	}

	.scroll-box {
		overflow-y: scroll;
		height: 100%;
		justify-content: flex-start;
		overflow-y: scroll;
		width: 100%;
		padding-bottom: 5rem;
	}

	.titleIdx {
		margin-right: 0rem;
	}
}
