html {
	font-size: 10px;
}

html,
body,
#root {
	width: 100%;
	height: 100%;
	overflow: hidden;
	overscroll-behavior: none;
	-webkit-text-size-adjust: 100%;
	-webkit-user-select:none;
	-moz-user-select:none;
	-o-user-select:none;
	user-select:none;
}

html,
body,
#root,
body * {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-tag-highlight-color: transparent;
}

section {
	position: relative;
	/* width: 100%; */
}

video,
img,
a {
	cursor: pointer;
	display: block;
	text-decoration: none;
	object-fit: contain;
	-webkit-tap-highlight-color: transparent;
}

p {
	word-wrap: break-word;
}

svg {
	-webkit-tag-highlight-color: transparent;
}

textarea,
input {
	width: 100%;
	border: none;
	background-color: transparent;
	outline: none;
}

/* ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
} */

/*定义滑块 内阴影+圆角*/
/* ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
} */

/*定义滚动条轨道 内阴影+圆角*/
/* ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.1);
} */
